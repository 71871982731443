import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const PublicLayout = lazy(() => import("../layouts/PublicLayout.js"));
const AdminLayout = lazy(() => import("../layouts/AdminLayout.js"));
/***** Pages ****/

const PublicProductView = lazy(() => import("../views/PublicProductView.js"));
const ProductDetailsView = lazy(() => import("../views/ProductDetailsView.js"));
const PublicProductViewBanner = lazy(() => import("../views/PublicProductViewBanner.js"));

const ProductListView = lazy(() => import("../views/admin/ProductListView.js"));
const BannerConfigView = lazy(() => import("../views/admin/BannerConfigView.js"));
const UserListView = lazy(() => import("../views/admin/UserListView.js"));
const ActivityListView = lazy(() => import("../views/admin/ActivityListView.js"));

/*const About = lazy(() => import("../views/About.js"));
const Details = lazy(() => import("../views/Details"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));*/

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      { path: "", exact: true, element: <PublicProductView /> },
      { path: "/details/*", exact: true, element: <ProductDetailsView /> },
      { path: "/banner", exact: true, element: <PublicProductViewBanner /> },
      /*{ path: "/badges", exact: true, element: <Badges /> },
      { path: "/about", exact: true, element: <About /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> }
      { path: "/starter", exact: true, element: <Starter /> },
      { path: "/about", exact: true, element: <About /> },
      { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/badges", exact: true, element: <Badges /> },
      { path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },*/
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      { path: "", exact: true,  element: <Navigate to="products" replace />},
      { path: "products", exact: true, element: <ProductListView /> },
      { path: "banner", exact: true, element: <BannerConfigView /> },
      { path: "user", exact: true, element: <UserListView /> },
      { path: "activity", exact: true, element: <ActivityListView /> }
    ]
  }
];

export default ThemeRoutes;
