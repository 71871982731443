
import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import AppContext from "./context/AppContext";
import ToastBar from "./components/ToastBar";
import React, { useState } from 'react';


const App = () => {
  const routing = useRoutes(Themeroutes);
  const [showToastBar, setShowToastBar] = useState(false);
  const [toastData, setToastData] = useState(null);
  const [toastAutoClose, setToastAutoClose] = useState(true);

  const toastBar= (data,toastAutoCloseFlag)=>{
    setToastData(data);
    if(toastAutoClose){
      setToastAutoClose(toastAutoCloseFlag);
    }else{
      setToastAutoClose(true);
    }
   
    setShowToastBar(true);
  }
  
  const closeHandler = () =>{
    setShowToastBar(false);
    setToastData(null);
  }
  return <AppContext.Provider value={{toast:toastBar}}>
    {showToastBar && <ToastBar title = {toastData.title} bodyText={toastData.text} onClose={closeHandler} autoClose = {toastAutoClose}></ToastBar>}
    <div className="dark">{routing}</div>
    
    

    </AppContext.Provider>;
};

export default App;
