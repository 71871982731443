import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import React, { useState, useEffect } from 'react';
import '../App.css'

const ToastBar = (props) => {
  const [amination, setAnimation] = useState("show-toast");

  useEffect(() => {

    //autoclose
    if(props.autoClose){
      setTimeout(() => {
        closeToast();
      }, 25000);
    }
  });

 

  const closeToast = () =>{
    setAnimation("hide-toast");
    setTimeout(() => {
      props.onClose();
    }, 200);
  }

  return (

    <div style={{cursor:"pointer"}} onClick={closeToast}
        aria-live="polite"
        aria-atomic="true"
        className={amination + " position-relative toast-bar"}>
        <ToastContainer
          className="p-3"
          position="top-end"
          style={{ display:'block'}}
        >
          <Toast bg="light">
            { props.title && <Toast.Header closeButton={false}>
              <strong className="me-auto">{props.title}</strong>
            </Toast.Header> }
            <Toast.Body>
            <medium className="me-auto">{props.bodyText}</medium>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>

  );
}
export default ToastBar;